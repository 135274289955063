<template>
  <div>
    <validation-observer
      ref="editTrainingCourse"
      #default="{ invalid }"
    >
      <!-- TrainingCourse Info: Input Fields -->
      <b-form @submit.prevent="submit">
        <b-row>
          <!-- Nazwa szkolenia -->
          <b-col
            cols="12"
          ><validation-provider
            #default="{ errors }"
            name="Nazwa szkolenia"
            rules="required|max:200"
            :custom-messages="{
              max: 'Nazwa szkolenia nie może być dłuższa niż 200 znaków.',
            }"
            autofocus
          >
            <b-form-group
              label="Nazwa szkolenia"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="trainingCourseData.name"
                :state="errors.length > 0 ? false : null"
                trim
                :disabled="!isAdmin"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <!-- Opis szkolenia -->
          <b-col
            cols="12"
            :lg="isAdmin ? 6 : null"
          ><validation-provider
            #default="{ errors }"
            name="Opis szkolenia"
            rules="max:4000|required"
            :custom-messages="{
              max: 'Opis szkolenia nie może być dłuższy niż 4000 znaków.',
            }"
          >
            <b-form-group
              label="Opis szkolenia"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="trainingCourseData.description"
                :state="errors.length > 0 ? false : null"
                trim
                max-rows="20"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </b-col>
          <template v-if="isAdmin">
            <!-- Data rozpoczęcia -->
            <b-col
              cols="12"
              md="6"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              name="Data rozpoczęcia"
              rules="required"
            >
              <b-form-group
                label="Data rozpoczęcia"
                label-for="date"
              >
                <b-form-datepicker
                  id="date"
                  v-model="startDate"
                  :state="errors.length > 0 ? false : null"
                  locale="pl"
                  hide-header
                  label-help=""
                  placeholder=""
                  start-weekday="1"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Godzina startu -->
            <b-col
              cols="12"
              lg="3"
              md="6"
            ><validation-provider
              #default="{ errors }"
              name="Godzina startu"
              rules="required"
            >
              <b-form-group
                label="Godzina startu"
                label-for="time"
              >
                <b-form-input
                  id="localization"
                  v-model="startTime"
                  :state="errors.length > 0 ? false : null"
                  type="time"
                  lazy
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Data zakończenia -->
            <b-col
              cols="12"
              md="6"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              ref="endDateField"
              name="Data zakończenia"
              :rules="{
                required: true,
                'date-after': startDate,
              }"
              :custom-messages="{
                'date-after': 'Data zakończenia jest przed datą rozpoczęcia.',
              }"
            >
              <b-form-group
                label="Data zakończenia"
                label-for="endDate"
              >
                <b-form-datepicker
                  id="endDate"
                  v-model="endDate"
                  :state="errors.length > 0 ? false : null"
                  locale="pl"
                  hide-header
                  label-help=""
                  placeholder=""
                  start-weekday="1"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Dział -->
            <b-col
              cols="12"
              md="6"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              name="Dział"
              rules="required"
            >
              <b-form-group
                label="Dział"
                label-for="department"
              >
                <div
                  v-b-tooltip.hover.v-warning="
                    hasEnrolments
                      ? 'Nie można zmienić działu, ponieważ na szkolenie zapisani są Uczestnicy.'
                      : null
                  "
                >
                  <v-select
                    id="department"
                    v-model="trainingCourseData.department"
                    :options="departmentOptions"
                    class="w-100"
                    :reduce="(val) => val.value"
                    :disabled="hasEnrolments"
                  />
                </div>

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Trener -->
            <b-col
              cols="12"
              md="6"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              name="Trener"
              rules="required"
            >
              <b-form-group
                label="Trener"
                label-for="trainer"
              >
                <v-select
                  id="trainer"
                  v-model="trainingCourseData.trainer"
                  :options="trainerOptions"
                  class="w-100"
                  :reduce="(val) => val.value"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Lokalizacja -->
            <b-col
              cols="12"
              md="6"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              name="Lokalizacja"
              rules="required|max:400"
              :custom-messages="{
                max: 'Lokalizacja nie może być dłuższa niż 400 znaków.',
              }"
            >
              <b-form-group
                label="Lokalizacja"
                label-for="localization"
              >
                <b-form-input
                  id="localization"
                  v-model="trainingCourseData.localization"
                  :state="errors.length > 0 ? false : null"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Ilość miejsc -->
            <b-col
              cols="12"
              md="3"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              name="Ilość miejsc"
              :rules="{
                required: true,
                'min-value': hasEnrolments
                  ? trainingCourseData.enrolmentsCount || 1
                  : 1,
                integer: true,
              }"
              :custom-messages="{
                'min-value':
                  hasEnrolments &&
                  trainingCourseData.numberOfParticipants <
                  trainingCourseData.enrolmentsCount
                    ? 'Nie można zmienić dostępnej ilości miejsc, ponieważ na szkolenie zapisana jest większa liczba Uczestników.'
                    : 'Ustaw 1 lub więcej miejsc.',
                integer: 'Wpisz liczbę całkowitą.',
              }"
            >
              <b-form-group
                label="Ilość miejsc"
                label-for="numberOfParticipants"
              >
                <b-form-input
                  id="numberOfParticipants"
                  v-model="trainingCourseData.numberOfParticipants"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  min="1"
                  number
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Koszt szkolenia -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            ><validation-provider
              #default="{ errors }"
              name="Koszt szkolenia"
              rules="required|price"
              :custom-messages="{
                price: 'Wpisz kwotę w formacie x,xx',
              }"
            >
              <b-form-group
                label="Koszt szkolenia"
                label-for="priceInGrosze"
              >
                <b-input-group
                  v-b-tooltip.hover.v-warning="
                    hasEnrolments
                      ? 'Nie można zmienić kosztu szkolenia, ponieważ na szkolenie zapisani są Uczestnicy.'
                      : null
                  "
                  append="PLN netto / uczestnik"
                >
                  <b-form-input
                    id="priceInGrosze"
                    v-model="price"
                    :state="errors.length > 0 ? false : null"
                    lazy
                    class="text-right"
                    :disabled="hasEnrolments"
                  />
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <!-- Status szkolenia -->
            <b-col
              cols="12"
              md="3"
              lg="3"
            ><validation-provider
              #default="{ errors }"
              name="Status szkolenia"
              rules="required"
            >
              <b-form-group
                label="Status szkolenia"
                label-for="status"
              >
                <div
                  v-b-tooltip.hover.v-warning="
                    hasEnrolments && originalStatus === 'PUBLIC'
                      ? 'Nie można zmienić statusu szkolenia, ponieważ na szkolenie zapisani są Uczestnicy.'
                      : null
                  "
                >
                  <v-select
                    id="status"
                    v-model="trainingCourseData.status"
                    :options="statusOptions"
                    class="w-100"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :disabled="hasEnrolments && originalStatus === 'PUBLIC'"
                  />
                </div>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group></validation-provider>
            </b-col>
          </template>
        </b-row>
        <h3>Materiały informacyjne</h3>
        <hr>
        <training-courses-files
          v-model="informationMaterialsFiles"
          :existing-files="
            mode === 'edit' ? trainingCourseData.informationMaterials : null
          "
          :files-to-delete.sync="informationMaterialsToDelete"
          upload
          class="mb-2"
        />
        <h3>Materiały poszkoleniowe</h3>
        <hr>
        <training-courses-files
          v-model="afterTrainingMaterialsFiles"
          :existing-files="
            mode === 'edit' ? trainingCourseData.afterTrainingMaterials : null
          "
          :files-to-delete.sync="afterTrainingMaterialsToDelete"
          upload
          class="mb-2"
        />

        <!-- Action Buttons -->
        <div class="d-sm-flex align-items-center">

          <b-form-checkbox
            v-if="trainingCourseData.status === 'PUBLIC'"
            v-model="sendInformationEmail_c"
            class="mb-1 d-block d-sm-none"
          >
            Wyślij dodatkowego maila do managerów
          </b-form-checkbox>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
            :disabled="loading || invalid || !!uploading"
          >
            {{ mode === "edit" ? "Zapisz zmiany" : "Dodaj nowe szkolenie" }}
          </b-button>
          <!-- <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!!uploading"
          @click="uploadFiles"
        >
          Upload test
        </b-button> -->
          <b-button
            variant="outline-secondary"
            to="/training-courses/list"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            class="mr-sm-1"
          >
            Anuluj
          </b-button>
          <b-form-checkbox
            v-if="trainingCourseData.status === 'PUBLIC'"
            v-model="sendInformationEmail_c"
            class="d-none d-sm-block flex-shrink-1"
          >
            Wyślij dodatkowego maila do managerów
          </b-form-checkbox>
        </div>

      </b-form>
    </validation-observer>
    <b-modal
      ref="uploadingModal"
      hide-header
      hide-footer
      :visible="!!uploading"
      no-close-on-esc
      no-close-on-backdrop
    >
      <h4>Przesyłanie plików...</h4>
      <b-progress
        animated
        :value="uploadProgress"
      />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  maxlength,
  required,
  email,
  minValue,
  price as priceValidator,
  time,
} from '@validations'
import departmentOptions from '@/helpers/departmentOptions'
import statusOptions from '@/helpers/trainingCourseStatusOptions'
import vSelect from 'vue-select'
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormDatepicker,
  BInputGroup,
  BModal,
  BProgress,
  BFormCheckbox,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import TrainingCoursesFiles from './TrainingCoursesFiles.vue'

export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    TrainingCoursesFiles,
    BModal,
    BProgress,
    BFormCheckbox,
  },

  props: {
    trainingCourseData: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'new',
    },
    sendInformationEmail: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      required,
      email,
      maxlength,
      minValue,
      time,
      priceValidator,
      departmentOptions,
      statusOptions,
      informationMaterialsFiles: [],
      informationMaterialsToDelete: [],
      afterTrainingMaterialsFiles: [],
      afterTrainingMaterialsToDelete: [],
      uploading: 0,
      originalStatus: null,
    }
  },
  computed: {
    sendInformationEmail_c: {
      get() { return this.sendInformationEmail },
      set(value) { return this.$emit('update:sendInformationEmail', value) },
    },
    startDate: {
      get() {
        return this.trainingCourseData.date
          ? dayjs(this.trainingCourseData.date).format('YYYY-MM-DD HH:mm')
          : null
      },
      set(value) {
        let setDate = dayjs(value)
        if (this.startTime) {
          const [hours, minutes] = this.startTime.split(':')
          setDate = setDate.hour(hours).minute(minutes)
        } else {
          setDate = setDate.hour(8).minute(0)
        }
        this.trainingCourseData.date = setDate.format('YYYY-MM-DD HH:mm')
        this.$nextTick(() => {
          this.$refs.endDateField.validate()
        })
      },
    },

    startTime: {
      get() {
        const dateObject = this.trainingCourseData.date
          ? dayjs(this.trainingCourseData.date).format('HH:mm:ss')
          : null
        return dateObject || null
      },
      set(value) {
        const [hours, minutes] = value.split(':')
        let setDate = dayjs(this.startDate)
        setDate = setDate.hour(hours).minute(minutes)
        this.trainingCourseData.date = setDate.format('YYYY-MM-DD HH:mm')
      },
    },

    endDate: {
      get() {
        return this.trainingCourseData.endDate
          ? dayjs(this.trainingCourseData.endDate).format('YYYY-MM-DD HH:mm')
          : null
      },
      set(value) {
        let setDate = dayjs(value)
        setDate = setDate.hour(23).minute(59).second(50)
        this.trainingCourseData.endDate = setDate.format('YYYY-MM-DD HH:mm')
      },
    },

    price: {
      get() {
        return (this.trainingCourseData.priceInGrosze / 100)
          .toFixed(2)
          .toString()
          .replace('.', ',')
      },
      set(value) {
        let formatted = parseFloat(value.replace(',', '.')).toFixed(2)
        if (formatted === 'NaN') {
          formatted = 0
        }
        this.trainingCourseData.priceInGrosze = formatted * 100
      },
    },

    trainerOptions() {
      if (
        !this.$store.state.user.list
        || !this.$store.state.user.list['hydra:member']
      ) {
        return []
      }
      const options = []
      this.$store.state.user.list['hydra:member'].forEach(user => {
        options.push({
          label: `${user.firstName} ${user.lastName}`,
          value: user['@id'],
        })
      })

      return options
    },

    uploadProgress() {
      const length = this.informationMaterialsFiles.length
        + this.afterTrainingMaterialsFiles.length
      return (Math.abs(this.uploading - length) / length) * 100
    },

    hasEnrolments() {
      return !!this.trainingCourseData.enrolmentsCount
    },
  },
  mounted() {
    this.$store.dispatch('user/fetchList', {
      params: {
        roles: 'ROLE_TRAINER',
      },
    })
    this.originalStatus = this.trainingCourseData.status
    if (this.mode === 'edit') this.$refs.editTrainingCourse.validate()
  },
  methods: {
    uploadFiles() {
      this.uploading = this.afterTrainingMaterialsFiles.length
        + this.informationMaterialsFiles.length

      const uploadNext = file => {
        const formdata = new FormData()
        formdata.append('file', file)
        return new Promise((resolve, reject) => {
          this.$http
            .post('media_objects', formdata)
            .then(response => {
              if (response && response.status === 201) {
                resolve(response)
              } else {
                reject(response)
              }
            })
            .catch(error => resolve(error))
        })
      }

      const uploadStack = (files, destination) => {
        const stack = []
        files.forEach(file => {
          stack.push(
            uploadNext(file)
              .then(response => {
                this.uploading -= 1
                // files.splice(files.indexOf(file), 1)
                destination.push(response.data['@id'])
              })
              .catch(error => {
                this.uploading = 0
                this.$root.$emit(
                  'global:error',
                  `Nie udało się załadować pliku ${file.name}. ${
                    error
                      ? error.data['hydra:description']
                      : 'Serwer nie odpowiada.'
                  }`,
                )
              }),
          )
        })
        return Promise.all(stack)
      }

      return new Promise(resolve => {
        if (
          !this.informationMaterialsFiles.length
          && !this.afterTrainingMaterialsFiles.length
        ) {
          resolve()
        } else {
          Promise.all([
            uploadStack(
              this.afterTrainingMaterialsFiles,
              this.trainingCourseData.afterTrainingMaterials,
            ),
            uploadStack(
              this.informationMaterialsFiles,
              this.trainingCourseData.informationMaterials,
            ),
          ]).then(() => {
            resolve()
          })
        }
      })
    },

    deleteFiles() {
      this.afterTrainingMaterialsToDelete.forEach(fileToDelete => {
        this.trainingCourseData.afterTrainingMaterials = this.trainingCourseData.afterTrainingMaterials.filter(
          file => file['@id'] !== fileToDelete['@id'],
        )
      })
      this.informationMaterialsToDelete.forEach(fileToDelete => {
        this.trainingCourseData.informationMaterials = this.trainingCourseData.informationMaterials.filter(
          file => file['@id'] !== fileToDelete['@id'],
        )
      })
    },

    submit() {
      this.$refs.editTrainingCourse.validate().then(valid => {
        if (valid) {
          if (this.mode === 'edit') this.deleteFiles()
          this.uploadFiles().then(() => {
            this.$emit('submit')
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
